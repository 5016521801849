export const partnerList = [
    {
        name: "Gold Gym",
        sale: "20%",
        tokenId: "7753894375:AAF0SlhOYDv8MU75DkvNBwuU5VuDFPxiF8o",
        chatId: "-4558528541"
    },

    {
        name: "Seda Farm",
        sale: "10%",
        tokenId: "7919607900:AAESSDQomcRQ2gBFpJ5NEXVZijW8FdA4kiY",
        chatId: "-4552058619"
    },
]