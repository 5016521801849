export const translationAM = {
    "About Us": "Մեր մասին",
    "Our Services": "Ծառայություններ",
    "Contact Us": "Կապ",
    "Book Now": "Ամրագրել հիմա",
    "Discover the ultimate in nail care luxury at CHIC ∙ CHOC Nail Salon. Treat yourself to expert manicures and pedicures in our serene and welcoming atmosphere. Book your appointment today for a rejuvenating experience you won't forget!": "Բացահայտեք եղունգների խնամքի լավագույն շքեղությունը ՇԻԿ ∙ ՇՈԿ եղունգների սրահում: Հաճելի մատնահարդարումներ և պեդիկյուրներ արեք մեր հանգիստ և հյուրընկալ մթնոլորտում: Ամրագրեք ձեր հանդիպումը այսօր երիտասարդացնող փորձի համար, որը չեք մոռանա",
    "Step into a Realm of Beauty and Elegance, Where Your Nails Become a Canvas of Creativity.": "Քայլ դեպի գեղեցկություն և էլեգանտություն, որտեղ քո եղունգները վերածվում են յուրահատուկ ստեղծագործության:",
    "Vardananc Մեկ6, Yerevan, Armenia": "Վարդանանց Մեկ6, Երևան, Հայաստան",
    "Our goal is to provide a luxurious and relaxing experience with excellent nail care and artistry, exceeding client expectations with a range of services including manicures, pedicures and creative nail designs.": "Մեր նպատակն է գերազանցել հաճախորդի սպասելիքները շքեղ և միևնույն պահին հանգիստ միջավայրում։ Մենք կփորձենք անել ամեն բան, քեզ գոհացնելու համար, արի՛ մեր մոտ և ամբողջացրու քո ոճը մեր պրոֆեսիոնալ մասնագետների օգնությամբ։",
    "Dare to dream differently with us!": "Ընդգծի՛ր քո ոճը, տարբերվի՛ր բոլորից",
    "Got a quirky wish? We've got you covered! Let us sprinkle some magic and make it real, reflecting your awesome personality. And hey, while we're at it, let's pamper those nails with some luxe treatment too!": "Ցանկացած քմահաճ ցանկություն քո կողմից՝ մենք սիրով կդարձնենք իրական, որպեսզի արտացոլվի քո յուրահատուկ անհատականությունը։ Ընդունի՛ ր շքեղությունը և մենք կապահովենք քո եղունգների խնամքն ու գեղեցկությունը։",
    "Manicure": "Մատնահարդարում",
    "Pedicure": "Ոտնահարդարում",
    "Get ready to witness perfection in action! Our awesome team is all about making your nails look and feel amazing. Whether you're after a fancy manicure or just chilling with a pedicure, we promise a super comfy vibe every step of the way.": "Բացահայտելով կատարելությունը՝ դու կտեսնես մեր նվիրված թիմի անգնահատելի աշխատանքը։ Նրանք կապահովեն քո եղունգների գեղեցիկ և առողջ տեսքը։ Անկախ նրանից, թե դու շքեղ մատնահարդարում ես ցանկանում, թե ռելաքս ոտնահարդարում, այնուամենայնիվ մենք երաշխավորում ենք կոմֆորտի զգացողությունը ողջ աշխատանքի ընթացքում։",
    "Go to Services": "Դեպի Ծառայություններ",
    "KIND WORDS FROM OUR CUSTOMERS": "ԿԱՐԾԻՔՆԵՐ ՀԱՃԱԽՈՐԴՆԵՐԻՑ",
    "Look no further! We've got everything you've been searching for: top-notch service, expert pros, and the perfect chill vibe for maximum comfort. Welcome to your new happy place!": "Մեզ մոտ դու կգտնես այն ինչ փնտրել ես մինչ այսօր՝ բարձր դասի սպասարկում, պրոֆեսիոնալ մասնագետներ և իդեալական մթնոլորտ, որտեղ դու քեզ կզգաս մաքսիմալ հարմարավետ։",
    "our story": "ՄԵՐ ՊԱՏՄՈՒԹՅՈՒՆԸ",
    "Okay, so picture this: Marieta Vardanyan, or Marik as her crew calls her, is this graphic designer, yeah? But get this – out of the blue, she's like, 'You know what Armenia needs? A killer nail salon!' So, she rolls up her sleeves, leaves the design board behind, and dives headfirst into the nail game. She's all about top-notch service, primo professionals, and making dreams come true. And that's how the magic began!": "Մի օր Մարիետա Վարդանյանը, ում անվանում են Մարիկ ու ով մասնագիտությամբ Գրաֆիկ դիզայներ է և բացարձակ կապ չունի այս ոլորտի հետ))) ցանկանալով բարձրացնել սպասարկման ոլորտը Հայաստանում՝ որոշում է բացել իր  երազանքի եղունգների սրահը, որը կազմված կլիներ բարձրորակ մասնագետներով և սուպեր իդեալական սպասարկումով և անցնում է գործի։",
    "Creating an ideal environment": "Կերտելով իդեալական միջավայր",
    "our team": "ՄԵՐ ԹԻՄԸ",
    "At our salon, our team is like a family, with each member bringing their unique skills to make your experience exceptional. We have a diverse group of specialists who are dedicated to making you look and feel your best. Our nail technicians are the artists behind those intricate nail designs you love. From classic manicures to elaborate nail art, they have the creativity and expertise to bring your vision to life. With years of experience and ongoing training, they stay updated on the latest trends and techniques, ensuring that your nails are always on point. But our team isn't just about technical skills – it's also about genuine care and connection. Each member is dedicated to making you feel welcomed, valued, and comfortable during your visit. They take the time to listen to your needs, answer your questions, and ensure that every aspect of your experience exceeds your expectations.": "Մեր թիմը նման է ընտանիքի, որտեղ յուրաքանչյուրը իր յուրահատուկ հմտություններով կատարյալ է դարձնում աշխատանքը։ Ձեր հիանալի տեսքի հովանավորները մեր մասնագետներն են։\n" +
        "Մեր սրահում մասնագետները կանեն նույնիսկ բարդագույն դիզայնով աշխատանքները։ Դասական մատնահարդարումից մինչև եղունգների գեղարվեստական մշակում, նրանք ունեն կրեատիվ միտք և բավական փորձ՝ ձեր նախընտրածը իրագործելու համար։ Տարիների փորձի և շարունակական ուսուցման շնորհիվ՝ նրանք միշտ կահապովեն ձեր եղունգների գեղեցիկ տեսքը։ \n" +
        "Մեր սրահի յուրաքանչյուր անդամ նվիրված է իր աշխատանքին և հնարավորինս ամեն բան արվում է ձեր իսկ հարմարավետության համար։ \n" +
        "Նրանք անգամ ժամանակ են հատկացնում ձեզ լսելու և հարկ եղած դեպքում պատասխանելու ձեր հարցերին, որպեսզի գերազանցեն Ձեր իսկ սպասելիքները։",
    "Visit our calm retreat and leave behind the busy pace of daily life. Our skilled technicians will provide you with exceptional nail care, creating the perfect experience for you.": "Այցելի՛ ր ՇԻԿ ∙ ՇՈԿ սրահ և հետևում թո՛ ղ առօրյա կյանքի զբաղվածությունը: Մեր հմուտ մասնագետները քեզ կտրամադրեն եղունգների բացառիկ խնամք՝ ստեղծելով կատարյալ արդյունք:",
    "Services": "Ծառայություններ",
    "Men's Manicure/Pedicure": "Տղամարդկանց Մատնա/ՈՏՆԱհարդարում",
    "Men's Manicure": "Տղամարդկանց Մատնահարդարում",
    "Men's Pedicure": "Տղամարդկանց Ոտնահարդարում",
    "Men's Pedicure (Without Heel Treatment)": "Տղամարդկանց Ոտնահարդարում (Առանց Կրունկի)",
    "Men's Paraffin Therapy For Hands": "Տղամարդկանց Ձեռքերի Պարաֆինային Թերապիա",
    "Men's Paraffin Therapy For Feet" : "Տղամարդկանց Ոտքերի Պարաֆինային Թերապիա",
    "Design": "Դիզայն",
    "Design Of Medium Complexity": "Միջին Բարդության Դիզայն",
    "Stemping": "Ստեմպինգ",
    "French": "ֆրենչ",
    "Ombre": "Օմբրե",
    "The Rub" : "Վտիրկա",
    "AMD": "֏",
    "hr": "ժ",
    "min": "ր",
    "Classic Manicure": "Դասական Մատնահարդարում",
    "Gel Lac + Manicure": "Մատնահարդարում + Գել Լաք",
    "Japan Style": "Ճապոնական Մատնահարդարում",
    "Nail Fixing": "Եղունգների Ամրեցում",
    "Nail Refill": "Եղունգների Լիցք",
    "Refill Correction": "Լիցքի Կորրեկցիա",
    "Repair Of One Nail": "Եղունգի նորոգում",
    "Padding For One Nail": "Եղունգի Լիցք ",
    "Gel Removal": "Գելլաքի Հեռացում",
    "Hand Acupressure": "Կետային Մերսում",
    "Manicure + Simple Lac": "Մատնահարդարում + Լաք",
    "Paraffin Therapy For Hands": "Պարաֆինային Թերապիա",
    "Pedicure + Simple Lac": "Ոտնահարդարում + լաք ",
    "Classic Pedicure": "Ոտնահարդարում",
    "Pedicure + Gel Lac": "Ոտնահարդարում + Գել Լաք",
    "Paraffin Therapy For Feet": "Ոտքերի Պարաֆինային Թերապիա",
    "Heel Treatment": "Կրունկների մշակում",
    "Gel Pedicure": "Ոտնահարդարում Գել Լաք",
    "Daily Make-Up": "Ամենօրյա Դիմահարդարում",
    "Wedding Make-Up": "Հարսանեկան Դիմահարդարում",
    "Removing The Charge": "Լիցքի Հեռացում",
    "Acupressure Of The Feet": "Կետային Մերսում",
    "Eyebrow Shaping": "Հոնքերի շտկում",
    "Lamination": "Հոնքերի Լամինացիա",
    "Waxing": "Ոսկային Մազահեռացում",
    "Simply fill in the necessary information to secure your appointment with us. From preferred service to date and time, your nail care needs are in good hands.": "Պարզապես լրացրու՛ անհրաժեշտ տեղեկատվությունը մեզ հետ քո հանդիպումը ապահովելու համար: Նախընտրելի ծառայությունից մինչև օր և ժամ։ Քո եղունգների խնամքը հուսալի ձեռքերում են:",
    "Book an Arrangement": "Ամրագրի՛ ր պայմանավորվածություն",
    "Name Surname": "Անուն Ազգանուն",
    "Choose Master": "Ընտրեք մասնագետ",
    "Irina Kostanyan": "Իրինա Կոստանյան",
    "Ani Martoyan": "Անի Մարտոյան",
    "Phone Number": "Հեռախոսահամար",
    "Select the Service Type": "Ծառայության տեսակ",
    "Book": "Ամրագրել",
    "The service will cost": "Ծառայությունը կարժենա",
    "Time": "Ժամ",
    "OOOPS! PAGE NOT FOUND": "ԷՋԸ ՉԻ ԳՏՆՎԵԼ",
    "Return Homepage": "Վերադառնալ Գլխավոր էջ",
    "Awesome atmosphere, cool service, best personal!Thank you for being so Chic!": "Հիանալի մթնոլորտ, հիանալի սպասարկում, լավագույն անձնակազմ: Շնորհակալություն այսքան շքեղ լինելու համար:",
    "Registration Successfully Completed": "Գրանցումը Հաջողությամբ կատարված է",
    "Fill in all fields": "Լրացրեք բոլոր դաշտերը",
    "Feel free to reach out to us using any of the methods below. Our dedicated team is ready to assist you and ensure that your experience with us is nothing short of exceptional.": "Կապ հաստատե՛ք մեզ հետ՝ օգտագործելով ստորև նշված տարբերակներից որևէ մեկը: Մեր անձնվեր թիմը պատրաստ է օգնել ձեզ և համոզվել, որ մեզ հետ ունեցած ձեր փորձը կլինի բացառիկ:",
    "Monday - Sunday": "Երկուշաբթիից Կիրակ",
    "Contact  Us": "Կապ մեզ հետ",
    "Fill in all the fields for registration": "Լրացրեք բոլոր դաշտերը գրանցվելու համար"
}